import React from 'react';
import { arrayOf, bool, func, node, object, shape, string } from 'prop-types';
import classNames from 'classnames';

import SectionContainer from '../SectionContainer';
import { AdSenseAd } from '../../../../components';
import { FormattedMessage } from '../../../../util/reactIntl';
import css from './SectionAd.module.css';

// Section component that's able to show article content
// The article content is mainly supposed to be inside a block
const SectionAd = props => {
  const {
    sectionId,
    className,
    rootClassName,
    defaultClasses,
    title,
    description,
    appearance,
    callToAction,
    blocks,
    isInsideContainer,
    options,
    config,
  } = props;

  // If external mapping has been included for fields
  // E.g. { h1: { component: MyAwesomeHeader } }
  const fieldComponents = options?.fieldComponents;
  const fieldOptions = { fieldComponents };

  return (
    <SectionContainer
      id={sectionId}
      className={classNames(className, css.sectionAd)}
      rootClassName={rootClassName}
      appearance={appearance}
      options={fieldOptions}
    >
      <div className={css.adWrapper}>
        <AdSenseAd
          config={config}
          slot="6209025635"
          width="728px"
          height="90px"
          className={css.googleAd}
        />
        <div className={css.disclaimer}>
          <FormattedMessage id="AdSenseAd.disclaimer" />
        </div>
      </div>
    </SectionContainer>
  );
};

const propTypeOption = shape({
  fieldComponents: shape({ component: node, pickValidProps: func }),
});

SectionAd.defaultProps = {
  className: null,
  rootClassName: null,
  defaultClasses: null,
  textClassName: null,
  title: null,
  description: null,
  appearance: null,
  callToAction: null,
  blocks: [],
  isInsideContainer: false,
  options: null,
};

SectionAd.propTypes = {
  sectionId: string.isRequired,
  className: string,
  rootClassName: string,
  defaultClasses: shape({
    sectionDetails: string,
    title: string,
    description: string,
    ctaButton: string,
  }),
  title: object,
  description: object,
  appearance: object,
  callToAction: object,
  blocks: arrayOf(object),
  isInsideContainer: bool,
  options: propTypeOption,
};

export default SectionAd;
