import React from 'react';

// Utils
import { SCHEMA_TYPE_MULTI_ENUM, SCHEMA_TYPE_TEXT } from '../../util/types';
import { EXCLUDED_LISTING_FIELDS } from '../../util/excludedListingFieldsConfig.js';
import {
  isFieldForCategory,
  pickCategoryFields,
  pickCustomFieldProps,
} from '../../util/fieldHelpers.js';

import SectionDetailsMaybe from './SectionDetailsMaybe';
import SectionMultiEnumMaybe from './SectionMultiEnumMaybe';
import SectionTextMaybe from './SectionTextMaybe';
import { AdSenseAd } from '../../components';
import { FormattedMessage } from '../../util/reactIntl';
import css from './ListingPage.module.css';

const FIELDS_FOR_ADS = ['samplefoodmenu', 'hoursofoperation']

/**
 * Renders custom listing fields.
 * - SectionDetailsMaybe is used if schemaType is 'enum', 'long', or 'boolean'
 * - SectionMultiEnumMaybe is used if schemaType is 'multi-enum'
 * - SectionTextMaybe is used if schemaType is 'text'
 *
 * @param {*} props include publicData, metadata, listingFieldConfigs, categoryConfiguration
 * @returns React.Fragment containing aforementioned components
 */
const CustomListingFields = props => {
  const { publicData, metadata, listingFieldConfigs, categoryConfiguration, config } = props;

  const { key: categoryPrefix, categories: listingCategoriesConfig } = categoryConfiguration;
  const categoriesObj = pickCategoryFields(publicData, categoryPrefix, 1, listingCategoriesConfig);
  const currentCategories = Object.values(categoriesObj);

  const isFieldForSelectedCategories = fieldConfig => {
    const isTargetCategory = isFieldForCategory(currentCategories, fieldConfig);
    return isTargetCategory;
  };
  const validListingFieldsConfig = listingFieldConfigs.filter(lf => !EXCLUDED_LISTING_FIELDS.includes(lf.key))
  const propsForCustomFields =
    pickCustomFieldProps(
      publicData,
      metadata,
      validListingFieldsConfig,
      'listingType',
      isFieldForSelectedCategories
    ) || [];

  return (
    <>
      <SectionDetailsMaybe {...props} isFieldForCategory={isFieldForSelectedCategories} />
      {propsForCustomFields.map(customFieldProps => {
        const { schemaType, ...fieldProps } = customFieldProps;
        const isFieldWithAd = FIELDS_FOR_ADS.includes(fieldProps?.key);
        const ad = isFieldWithAd ? 
        <div className={css.adWrapper}>
          <AdSenseAd config={config}
            slot="6209025635"
            width="728px"
            height="90px"
            className={css.googleAd}
          />
          <div className={css.disclaimer}>
            <FormattedMessage id="AdSenseAd.disclaimer" />
          </div>
        </div> : null
        const field = schemaType === SCHEMA_TYPE_MULTI_ENUM ? (
          <SectionMultiEnumMaybe {...fieldProps} />
        ) : schemaType === SCHEMA_TYPE_TEXT ? (
          <SectionTextMaybe {...fieldProps} />
        ) : null;
        return <>
          {ad}
          {field}
        </>
      })}
    </>
  );
};

export default CustomListingFields;
